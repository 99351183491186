import { from, of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";

import {
  SEARCH_GRACENOTE_STATIONS,
  searchGracenoteStationsSuccess,
  searchGracenoteStationsFailure,
} from "../station-search/actions";

import { gracenoteAPI } from "../../data/gracenote";

const gracenoteClient = gracenoteAPI("r2w33jyvj772syta9gepe7e4");

export const searchGracenoteStationsEpic = (action$) =>
  action$.pipe(
    ofType(SEARCH_GRACENOTE_STATIONS),
    switchMap((action) => {
      return from(gracenoteClient.stationSearch(action.payload.query)).pipe(
        map((response) => response.data),
        catchError((error) => {
          console.log(error);
        })
      );
    }),
    map((stations) => {
      console.log(stations);
      return searchGracenoteStationsSuccess(stations);
    }),
    catchError((error) => {
      console.log(error);
      of(searchGracenoteStationsFailure(error.message));
    })
  );
