import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Typography, Container, Button } from "@material-ui/core";

import { getUser, isLoadingSelector } from "../../redux/auth/selectors";

import { login } from "../../redux/auth/actions";

export function LoginPage(props) {
  const { isLoading, user, login } = props;

  return (
    <Container>
      {!isLoading && user == null && <Button onClick={login}>Login</Button>}
      {isLoading && (
        <Typography variant="body1">Loading Projects...</Typography>
      )}
      {!isLoading && user !== null && <Redirect to="/projects" />}
    </Container>
  );
}

export default connect(
  state => ({
    isLoading: isLoadingSelector(state),
    user: getUser(state)
  }),
  {
    login
  }
)(LoginPage);
