import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Typography,
  IconButton,
  Button,
  Paper,
  Drawer,
  TextField
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { fetchProjects as fetchProjectsSelectors } from "../../redux/projects/selectors";

import {
  fetchProjects,
  createProject,
  deleteProject
} from "../../redux/projects/actions";

import ProjectList from "./project-list";

export function ProjectListPage(props) {
  const {
    isLoading,
    projects,
    fetchProjects,
    createProject,
    deleteProject
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [isCreateFormShown, setIsCreateFormShown] = useState(false);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  return (
    <div>
      <Paper style={{ width: "50%", margin: "auto", padding: 30 }}>
        <Typography variant="h4" style={{ marginBottom: 10 }}>
          Projects
        </Typography>
        {!isEditing && (
          <IconButton
            onClick={() => {
              setIsCreateFormShown(true);
            }}
          >
            <AddIcon />
          </IconButton>
        )}
        <Button
          onClick={() => {
            setIsEditing(!isEditing);
          }}
        >
          {isEditing ? "Cancel" : "Edit"}
        </Button>
        {isLoading && (
          <Typography variant="body1">Loading Projects...</Typography>
        )}
        {!isLoading &&
          (projects == null || projects.length !== 0) &&
          ProjectList({
            projects,
            isEditable: isEditing,
            onProjectDelete: projectId => {
              deleteProject({ projectId });
            }
          })}
      </Paper>
      <Drawer
        style={{
          display: "flex",
          flexShrink: 0
        }}
        open={isCreateFormShown}
        onClose={() => setIsCreateFormShown(false)}
        anchor="right"
      >
        <ProjectForm
          onCreatePressed={project => {
            createProject(project);
            setIsCreateFormShown(false);
          }}
        />
      </Drawer>
    </div>
  );
}

export default connect(
  state => ({
    isLoading: fetchProjectsSelectors.isLoading(state),
    projects: fetchProjectsSelectors.getProjects(state)
  }),
  {
    fetchProjects,
    createProject,
    deleteProject
  }
)(ProjectListPage);

function ProjectForm(props) {
  const { onCreatePressed } = props;

  const [name, setName] = useState("");

  return (
    <div>
      <Typography variant="h2">Create Project</Typography>
      <TextField
        placeholder="Name"
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <div>
        <Button
          onClick={() => {
            onCreatePressed({ name });
          }}
        >
          Create
        </Button>
      </div>
    </div>
  );
}
