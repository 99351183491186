import {
  AUTH_LOGIN,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGOUT,
  AUTH_STATUS_UPDATED
} from "./actions";

const initialstate = {
  credential: null,
  user: null,
  isLoading: false,
  error: null
};

export default function(state = initialstate, action) {
  switch (action.type) {
    // Login
    case AUTH_LOGIN: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }
    case AUTH_LOGIN_SUCCESS: {
      return {
        token: action.payload.credential,
        user: action.payload.user,
        isLoading: false,
        error: null
      };
    }
    case AUTH_LOGIN_FAILURE: {
      return {
        token: null,
        user: null,
        isLoading: false,
        error: action.payload
      };
    }

    case AUTH_STATUS_UPDATED: {
      return {
        ...state,
        user: action.payload
      };
    }

    // Logout
    case AUTH_LOGOUT: {
      return {
        token: null,
        user: null,
        isLoading: false,
        error: null
      };
    }

    default: {
      return state;
    }
  }
}
