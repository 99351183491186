import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import {
  AppBar,
  Typography,
  Toolbar,
  makeStyles,
  Link
} from "@material-ui/core";

import { appStart } from "./redux/lifecycle/actions";
import LoginPage from "./components/auth/login";
import ProjectListPage from "./components/project-list/project-list-page";
import WorkspaceMainPage from "./components/workspace-main/workspace-main.page";
import { getUser } from "./redux/auth/selectors";

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  toolbar: theme.mixins.toolbar
}));

export function App(props) {
  const { appStart, user } = props;

  const classes = useStyles();

  useEffect(() => {
    appStart();
  }, [appStart]);

  return (
    <Router>
      <div>
        <AppBar className={classes.appBar} position="fixed">
          <Toolbar>
            <Typography variant="h6">
              <Link href="/projects" color="inherit">
                Channel Guide Tool
              </Link>
            </Typography>
          </Toolbar>
        </AppBar>

        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
          }}
        >
          <div className={classes.toolbar} />
          <Switch>
            <Route path="/projects">
              <ProjectListPage />
            </Route>
            <Route path="/project/:id">
              <WorkspaceMainPage />
            </Route>
            <Route path="/">
              {!user && <LoginPage />}
              {user && <Redirect to="/projects"></Redirect>}
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default connect(
  state => ({
    user: getUser(state)
  }),
  {
    appStart
  }
)(App);
