import React, { useState } from "react";

import map from "lodash/fp/map";

import {
  makeStyles,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  IconButton,
  Drawer,
  Button,
  TextField,
  ListItemSecondaryAction
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

const drawerWidth = 500;
const useStyles = makeStyles(theme => ({
  formDrawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: theme.zIndex.drawer + 2
  }
}));

export function ProjectChannelGuideList(props) {
  const {
    channelGuides,
    onChannelGuideSelected,
    onChannelGuideDeleted,
    isEditable
  } = props;

  const makeChannelGuideListItems = map(channelGuide => (
    <ListItem
      button={!isEditable}
      onClick={_event => {
        if (!isEditable && onChannelGuideSelected)
          onChannelGuideSelected(channelGuide);
      }}
      key={channelGuide.id}
    >
      <ListItemText
        disableTypography={true}
        primary={
          <Typography variant="subtitle1" color="textPrimary">
            {channelGuide.name}
          </Typography>
        }
        secondary={
          <React.Fragment>
            <Typography variant="body2" color="textPrimary">
              {channelGuide.id}
            </Typography>
          </React.Fragment>
        }
      />
      {isEditable && (
        <ListItemSecondaryAction>
          <IconButton
            onClick={_event => {
              onChannelGuideDeleted(channelGuide.id);
            }}
            edge="end"
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  ));

  return <List>{makeChannelGuideListItems(channelGuides)}</List>;
}

export default function ProjectChannelGuideEditList(props) {
  const {
    channelGuides,
    onChannelGuideSelected,
    onChannelGuideAdded,
    onChannelGuideDeleted
  } = props;

  const [isCreateFormShown, setIsCreateFormShown] = useState(false);

  const [isEditable, setIsEditable] = useState(false);
  const classes = useStyles();

  return (
    <div>
      {!isEditable && (
        <IconButton
          onClick={() => {
            setIsCreateFormShown(true);
          }}
        >
          <AddIcon />
        </IconButton>
      )}
      <Button
        onClick={() => {
          setIsEditable(!isEditable);
        }}
      >
        {isEditable ? "Cancel" : "Edit"}
      </Button>
      <ProjectChannelGuideList
        isEditable={isEditable}
        channelGuides={channelGuides}
        onChannelGuideSelected={onChannelGuideSelected}
        onChannelGuideDeleted={onChannelGuideDeleted}
      />
      <Drawer
        open={isCreateFormShown}
        onClose={() => setIsCreateFormShown(false)}
        className={classes.formDrawer}
        anchor="right"
      >
        <ProjectChannelGuideForm
          onCreatePressed={channelGuide => {
            onChannelGuideAdded(channelGuide);
          }}
        />
      </Drawer>
    </div>
  );
}

function ProjectChannelGuideForm(props) {
  const { onCreatePressed } = props;

  const [name, setName] = useState("");

  return (
    <div>
      <Typography variant="h2">Channel Guide</Typography>
      <TextField
        placeholder="Name"
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <Button
        onClick={() => {
          onCreatePressed({ name });
        }}
      >
        Create
      </Button>
    </div>
  );
}
