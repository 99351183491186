import { get } from "lodash/fp";

export const isLoadingSelector = state => (
  get('gracenoteLineups.isLoading')(state)
);

export const getLineupsSelector = state => (
  get('gracenoteLineups.lineups')(state)
);

export const selectedLineupSelector = state => (
  get('gracenoteLineups.selectedLineup')(state)
);