import { 
  FETCH_GRACENOTE_CHANNELS,
  FETCH_GRACENOTE_CHANNELS_FAILURE,
  FETCH_GRACENOTE_CHANNELS_SUCCESS
} from "./actions";

const initialstate = {
  channels: [],
  isLoading: false,
  error: null
}

export default function(state = initialstate, action) {
  switch (action.type) {
    case FETCH_GRACENOTE_CHANNELS: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }
    case FETCH_GRACENOTE_CHANNELS_SUCCESS: {
      return {
        channels: [...action.payload],
        isLoading: false,
        error: null
      };
    }
    case FETCH_GRACENOTE_CHANNELS_FAILURE: {
      return {
        channels: [],
        isLoading: false,
        error: action.payload
      }
    }
    default: {
      return state
    }
  }
}