// Fetch
export const PROJECT_CHANNEL_GUIDE_ENTRIES_FETCH =
  "PROJECT_CHANNEL_GUIDE_ENTRIES_FETCH";
export const PROJECT_CHANNEL_GUIDE_ENTRIES_FETCH_SUCCESS =
  "PROJECT_CHANNEL_GUIDE_ENTRIES_FETCH_SUCCESS";
export const PROJECT_CHANNEL_GUIDE_ENTRIES_FETCH_FAILURE =
  "PROJECT_CHANNEL_GUIDE_ENTRIES_FETCH_FAILURE";
export const fetchProjectChannelGuideEntries = options => ({
  type: PROJECT_CHANNEL_GUIDE_ENTRIES_FETCH,
  payload: options
});
export const fetchProjectChannelGuideEntriesSuccess = channelGuideEntries => ({
  type: PROJECT_CHANNEL_GUIDE_ENTRIES_FETCH_SUCCESS,
  payload: channelGuideEntries
});
export const fetchProjectChannelGuideEntriesFailure = message => ({
  type: PROJECT_CHANNEL_GUIDE_ENTRIES_FETCH_FAILURE,
  payload: message
});

// Add
export const PROJECT_CHANNEL_GUIDE_ENTRY_ADD =
  "PROJECT_CHANNEL_GUIDE_ENTRY_ADD";
export const PROJECT_CHANNEL_GUIDE_ENTRY_ADD_SUCCESS =
  "PROJECT_CHANNEL_GUIDE_ENTRY_ADD_SUCCESS";
export const PROJECT_CHANNEL_GUIDE_ENTRY_ADD_FAILURE =
  "PROJECT_CHANNEL_GUIDE_ENTRY_ADD_FAILURE";
export const addProjectChannelGuideEntry = options => {
  return {
    type: PROJECT_CHANNEL_GUIDE_ENTRY_ADD,
    payload: options
  };
};
export const addProjectChannelGuideEntrySuccess = success => ({
  type: PROJECT_CHANNEL_GUIDE_ENTRY_ADD_SUCCESS,
  payload: success
});
export const addProjectChannelGuideEntryFailure = message => ({
  type: PROJECT_CHANNEL_GUIDE_ENTRY_ADD_FAILURE,
  payload: message
});

// Delete
export const PROJECT_CHANNEL_GUIDE_ENTRY_DELETE =
  "PROJECT_CHANNEL_GUIDE_ENTRY_DELETE";
export const PROJECT_CHANNEL_GUIDE_ENTRY_DELETE_SUCCESS =
  "PROJECT_CHANNEL_GUIDE_ENTRY_DELETE_SUCCESS";
export const PROJECT_CHANNEL_GUIDE_ENTRY_DELETE_FAILURE =
  "PROJECT_CHANNEL_GUIDE_ENTRY_DELETE_FAILURE";
export const deleteProjectChannelGuideEntry = options => {
  return {
    type: PROJECT_CHANNEL_GUIDE_ENTRY_DELETE,
    payload: options
  };
};
export const deleteProjectChannelGuideEntrySuccess = success => ({
  type: PROJECT_CHANNEL_GUIDE_ENTRY_DELETE_SUCCESS,
  payload: success
});
export const deleteProjectChannelGuideEntryFailure = message => ({
  type: PROJECT_CHANNEL_GUIDE_ENTRY_DELETE_FAILURE,
  payload: message
});

// Updatge
export const PROJECT_CHANNEL_GUIDE_ENTRY_UPDATE =
  "PROJECT_CHANNEL_GUIDE_ENTRY_UPDATE";
export const PROJECT_CHANNEL_GUIDE_ENTRY_UPDATE_SUCCESS =
  "PROJECT_CHANNEL_GUIDE_ENTRY_UPDATE_SUCCESS";
export const PROJECT_CHANNEL_GUIDE_ENTRY_UPDATE_FAILURE =
  "PROJECT_CHANNEL_GUIDE_ENTRY_UPDATE_FAILURE";
export const updateProjectChannelGuideEntry = options => {
  return {
    type: PROJECT_CHANNEL_GUIDE_ENTRY_UPDATE,
    payload: options
  };
};
export const updateProjectChannelGuideEntrySuccess = success => ({
  type: PROJECT_CHANNEL_GUIDE_ENTRY_UPDATE_SUCCESS,
  payload: success
});
export const updateProjectChannelGuideEntryFailure = message => ({
  type: PROJECT_CHANNEL_GUIDE_ENTRY_UPDATE_FAILURE,
  payload: message
});
