import { 
  FETCH_GRACENOTE_LINEUPS, 
  FETCH_GRACENOTE_LINEUPS_FAILURE, 
  FETCH_GRACENOTE_LINEUPS_SUCCESS,
  SELECT_GRACENOTE_LINEUP
} from "./actions";

const initialstate = {
  selectedLineup: null, 
  lineups: [],
  isLoading: false,
  error: null
}

export default function(state = initialstate, action) {
  switch (action.type) {
    case FETCH_GRACENOTE_LINEUPS: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }
    case FETCH_GRACENOTE_LINEUPS_SUCCESS: {
      return {
        lineups: [...action.payload],
        isLoading: false,
        error: null
      };
    }
    case FETCH_GRACENOTE_LINEUPS_FAILURE: {
      return {
        lineups: [],
        isLoading: false,
        error: action.payload
      }
    }
    case SELECT_GRACENOTE_LINEUP: {
      return  {
        ...state,
        selectedLineup: action.payload
      }
    }
    default: {
      return state
    }
  }
}