import {
  // Add
  PROJECT_GRACENOTE_LINEUP_ADD,
  PROJECT_GRACENOTE_LINEUP_ADD_SUCCESS,
  PROJECT_GRACENOTE_LINEUP_ADD_FAILURE,
  // Delete
  PROJECT_GRACENOTE_LINEUP_DELETE,
  PROJECT_GRACENOTE_LINEUP_DELETE_SUCCESS,
  PROJECT_GRACENOTE_LINEUP_DELETE_FAILURE
} from "./actions";

const initialState = {
  add: {
    success: null,
    isLoading: false,
    error: null
  },
  delete: {
    success: null,
    isLoading: false,
    error: null
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    // Create
    case PROJECT_GRACENOTE_LINEUP_ADD: {
      return {
        ...state,
        add: {
          success: null,
          isLoading: true,
          error: null
        }
      };
    }
    case PROJECT_GRACENOTE_LINEUP_ADD_SUCCESS: {
      return {
        ...state,
        add: {
          success: action.payload,
          isLoading: false,
          error: null
        }
      };
    }
    case PROJECT_GRACENOTE_LINEUP_ADD_FAILURE: {
      return {
        ...state,
        add: {
          success: false,
          isLoading: false,
          error: action.payload
        }
      };
    }

    // Delete
    case PROJECT_GRACENOTE_LINEUP_DELETE: {
      return {
        ...state,
        delete: {
          success: null,
          isLoading: true,
          error: null
        }
      };
    }
    case PROJECT_GRACENOTE_LINEUP_DELETE_SUCCESS: {
      return {
        ...state,
        delete: {
          success: action.payload,
          isLoading: false,
          error: null
        }
      };
    }
    case PROJECT_GRACENOTE_LINEUP_DELETE_FAILURE: {
      return {
        ...state,
        delete: {
          success: false,
          isLoading: false,
          error: action.payload
        }
      };
    }
    default: {
      return state;
    }
  }
}
