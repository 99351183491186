import axios from "axios";

export function gracenoteAPI(apiKey) {
  return {
    findLineups: ({ country, postalCode }) =>
      axios.get(
        "https://data.tmsapi.com/v1.1/lineups",
        {
          dataType: "jsonp",
          params: {
            api_key: apiKey,
            country,
            postalCode,
          },
        }
      ),
    lineupChannelList: (lineupId) =>
      axios.get(
        `https://data.tmsapi.com/v1.1/lineups/${lineupId}/channels`,
        {
          dataType: "jsonp",
          params: {
            api_key: apiKey,
          },
        }
      ),
    stationSearch: (q, queryFields = "name,callsign", limit = 20, offset = 0) =>
      axios.get(
        "https://data.tmsapi.com/v1.1/stations/search",
        {
          dataType: "jsonp",
          params: {
            api_key: apiKey,
            q,
            queryFields,
            limit,
            offset,
          },
        }
      ),
  };
}
