import React from "react";

import MaterialTable from "material-table";
import { Avatar, Button } from "@material-ui/core";

import orderBy from "lodash/fp/orderBy";

export default function ChannelGuideComposer(props) {
  const {
    channelGuideEntries,
    updateChannelGuideEntry,
    deleteChannelGuideEntry
  } = props;

  const orderedChannelGuideEntries = orderBy(
    "ordinal",
    "asc"
  )(channelGuideEntries);

  return (
    <div style={{ width: "100%" }}>
      <MaterialTable
        style={{ width: "100%" }}
        options={{ paging: false, actionsColumnIndex: -1, sorting: false }}
        columns={[
          {
            title: "Logo",
            field: "preferredImage",
            render: data => (
              <Avatar src={data.preferredImage.uri} variant="rounded" />
            ),
            editable: "never"
          },
          { title: "Call Sign", field: "callSign", editable: "never" },
          { title: "Station ID", field: "stationId", editable: "never" },
          {
            title: "Affiliate Call Sign",
            field: "affiliateCallSign",
            editable: "never"
          },
          { title: "MRL", field: "mrl" },
          {
            title: "Sort Order",
            field: "ordinal",
            editable: "never",
            hidden: true,
            defaultSort: true
          }
        ]}
        data={orderedChannelGuideEntries}
        title="Channel Guide Composer"
        editable={{
          onRowUpdate: (newEntry, _oldEntry) => {
            updateChannelGuideEntry(newEntry);
            return Promise.resolve();
          },
          onRowDelete: entry => {
            deleteChannelGuideEntry(entry);
            return Promise.resolve();
          }
        }}
        actions={[
          {
            icon: "keyboard_arrow_down",
            onClick: (_event, rowData) => {
              const ordinal = rowData.ordinal || 0;
              if (ordinal + 1 < channelGuideEntries.length) {
                const nextChannelGuideEntry =
                  orderedChannelGuideEntries[ordinal + 1];
                updateChannelGuideEntry({
                  ...rowData,
                  ordinal: ordinal + 1
                });
                updateChannelGuideEntry({
                  ...nextChannelGuideEntry,
                  ordinal: ordinal
                });
              }
            }
          },
          {
            icon: "keyboard_arrow_up",
            onClick: (_event, rowData) => {
              const ordinal = rowData.ordinal || 0;
              if (ordinal - 1 >= 0) {
                const previousChannelGuideEntry =
                  orderedChannelGuideEntries[ordinal - 1];
                updateChannelGuideEntry({
                  ...rowData,
                  ordinal: ordinal - 1
                });
                updateChannelGuideEntry({
                  ...previousChannelGuideEntry,
                  ordinal: ordinal
                });
              }
            }
          }
        ]}
      />
    </div>
  );
}
