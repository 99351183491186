import React from "react";

import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  IconButton
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import map from "lodash/fp/map";

function ProjectGracenoteLineupListItem(props) {
  const { isEditable, lineup, onSelectLineup, onDeleteLineup } = props;

  return (
    <div key={lineup}>
      <ListItem
        button={!isEditable}
        onClick={() => {
          if (!isEditable && onSelectLineup != undefined)
            onSelectLineup(lineup);
        }}
      >
        <ListItemText
          disableTypography={true}
          primary={
            <Typography variant="subtitle1" color="textPrimary">
              {lineup.name}
            </Typography>
          }
        />
        {isEditable && (
          <ListItemSecondaryAction>
            <IconButton
              onClick={_event => {
                onDeleteLineup(lineup);
              }}
              edge="end"
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      <Divider />
    </div>
  );
}

export function ProjectGracenoteLineupList(props) {
  const { isEditable, lineups, onSelectLineup, onDeleteLineup } = props;

  const makeLineupListItems = map(lineup => (
    <ProjectGracenoteLineupListItem
      lineup={lineup}
      isEditable={isEditable}
      onSelectLineup={_event => {
        if (onSelectLineup != undefined) onSelectLineup(lineup);
      }}
      onDeleteLineup={onDeleteLineup}
    />
  ));

  return (
    <div>
      {lineups && lineups.length !== 0 && (
        <List>{makeLineupListItems(lineups)}</List>
      )}
    </div>
  );
}
