// Fetch
export const PROJECT_CHANNEL_GUIDES_FETCH_SUCCESS =
  "PROJECT_CHANNEL_GUIDE_FETCH_SUCCESS";
export const fetchProjectChannelGuidesSuccess = channelGuides => ({
  type: PROJECT_CHANNEL_GUIDES_FETCH_SUCCESS,
  payload: channelGuides
});

// Add
export const PROJECT_CHANNEL_GUIDE_ADD = "PROJECT_CHANNEL_GUIDE_ADD";
export const PROJECT_CHANNEL_GUIDE_ADD_SUCCESS =
  "PROJECT_CHANNEL_GUIDE_ADD_SUCCESS";
export const PROJECT_CHANNEL_GUIDE_ADD_FAILURE =
  "PROJECT_CHANNEL_GUIDE_ADD_FAILURE";
export const addProjectChannelGuide = options => {
  return {
    type: PROJECT_CHANNEL_GUIDE_ADD,
    payload: options
  };
};
export const addProjectChannelGuideSuccess = success => ({
  type: PROJECT_CHANNEL_GUIDE_ADD_SUCCESS,
  payload: success
});
export const addProjectChannelGuideFailure = message => ({
  type: PROJECT_CHANNEL_GUIDE_ADD_FAILURE,
  payload: message
});

// Delete
export const PROJECT_CHANNEL_GUIDE_DELETE = "PROJECT_CHANNEL_GUIDE_DELETE";
export const PROJECT_CHANNEL_GUIDE_DELETE_SUCCESS =
  "PROJECT_CHANNEL_GUIDE_DELETE_SUCCESS";
export const PROJECT_CHANNEL_GUIDE_DELETE_FAILURE =
  "PROJECT_CHANNEL_GUIDE_DELETE_FAILURE";
export const deleteProjectChannelGuide = options => ({
  type: PROJECT_CHANNEL_GUIDE_DELETE,
  payload: options
});
export const deleteProjectChannelGuideSuccess = success => ({
  type: PROJECT_CHANNEL_GUIDE_DELETE_SUCCESS,
  payload: success
});
export const deleteProjectChannelGuideFailure = message => ({
  type: PROJECT_CHANNEL_GUIDE_DELETE_FAILURE,
  payload: message
});

// Select
export const PROJECT_CHANNEL_GUIDE_SELECT_ACTIVE =
  "PROJECT_CHANNEL_GUIDE_SELECT_ACTIVE";
export const selectActiveProjectChannelGuide = project => ({
  type: PROJECT_CHANNEL_GUIDE_SELECT_ACTIVE,
  payload: project
});

export const PROJECT_CHANNEL_GUIDE_SELECT_CLEAR =
  "PROJECT_CHANNEL_GUIDE_SELECT_CLEAR";
export const projectChannelGuideSelectClear = () => ({
  type: PROJECT_CHANNEL_GUIDE_SELECT_CLEAR
});

// Publish
export const PROJECT_CHANNEL_GUIDE_PUBLISH = "PROJECT_CHANNEL_GUIDE_PUBLISH";
export const PROJECT_CHANNEL_GUIDE_PUBLISH_SUCCESS =
  "PROJECT_CHANNEL_GUIDE_PUBLISH_SUCCESS";
export const PROJECT_CHANNEL_GUIDE_PUBLISH_FAILURE =
  "PROJECT_CHANNEL_GUIDE_PUBLISH_FAILURE";
export const publishProjectChannelGuide = channelGuide => ({
  type: PROJECT_CHANNEL_GUIDE_PUBLISH,
  payload: channelGuide
});
export const publishProjectChannelGuideSuccess = success => ({
  type: PROJECT_CHANNEL_GUIDE_PUBLISH_SUCCESS,
  payload: success
});
export const publishProjectChannelGuideFailure = message => ({
  type: PROJECT_CHANNEL_GUIDE_PUBLISH_FAILURE,
  payload: message
});
