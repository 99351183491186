import { APP_START } from "./actions";

const initialstate = {
  isReady: false
}

export default function(state = initialstate, action) {
  switch (action.type) {
    case APP_START: {
      return {
        isReady: true
      };
    }
    default: {
      return state
    }
  }
}