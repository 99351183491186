import { get } from "lodash/fp";

export const fetchProjects = {
  isLoading: state => get("projects.fetchProjects.isLoading")(state),
  getProjects: state => get("projects.fetchProjects.projects")(state)
};

export const select = {
  selectProject: state => get("projects.selectProject.activeProject")(state)
};
// onst initialstate = {
//   fetchProjects: {
//     projects: [],
//     isLoading: false,
//     error: null
//   },
//   createProject: {
//     success: null,
//     isLoading: false,
//     error: null
//   },
//   deleteProject: {
//     success: null,
//     isLoading: false,
//     error: null
//   },
//   updateProject: {
//     success: null,
//     isLoading: false,
//     error: null
//   },
//   selectProject: {
//     activeProject: null
//   }
// };
