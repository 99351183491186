// Projects

// Projects Fetch
export const PROJECTS_FETCH = "PROJECTS_FETCH";
export const PROJECTS_FETCH_SUCCESS = "PROJECTS_FETCH_SUCCESS";
export const PROJECTS_FETCH_FAILURE = "PROJECTS_FETCH_FAILURE";
export const fetchProjects = () => {
  return {
    type: PROJECTS_FETCH
  };
};
export const fetchProjectsSuccess = projects => ({
  type: PROJECTS_FETCH_SUCCESS,
  payload: projects
});
export const fetchProjectsFailure = message => ({
  type: PROJECTS_FETCH_FAILURE,
  payload: message
});

// Project Create
export const PROJECT_CREATE = "PROJECT_CREATE";
export const PROJECT_CREATE_SUCCESS = "PROJECT_CREATE_SUCCESS";
export const PROJECT_CREATE_FAILURE = "PROJECT_CREATE_FAILURE";
export const createProject = project => ({
  type: PROJECT_CREATE,
  payload: project
});
export const createProjectSuccess = success => ({
  type: PROJECT_CREATE_SUCCESS,
  payload: success
});
export const createProjectFailure = message => ({
  type: PROJECT_CREATE_FAILURE,
  payload: message
});

// Project Delete
export const PROJECT_DELETE = "PROJECT_DELETE";
export const PROJECT_DELETE_SUCCESS = "PROJECT_DELETE_SUCCESS";
export const PROJECT_DELETE_FAILURE = "PROJECT_DELETE_FAILURE";
export const deleteProject = project => ({
  type: PROJECT_DELETE,
  payload: project
});
export const deleteProjectSuccess = success => ({
  type: PROJECT_DELETE_SUCCESS,
  payload: success
});
export const deleteProjectFailure = message => ({
  type: PROJECT_DELETE_FAILURE,
  payload: message
});

// Project Update
export const PROJECT_UPDATE = "PROJECT_UPDATE";
export const PROJECT_UPDATE_SUCCESS = "PROJECT_UPDATE_SUCCESS";
export const PROJECT_UPDATE_FAILURE = "PROJECT_UPDATE_FAILURE";
export const updateProject = project => ({
  type: PROJECT_UPDATE,
  payload: project
});
export const updateProjectSuccess = success => ({
  type: PROJECT_UPDATE_SUCCESS,
  payload: success
});
export const updateProjectFailure = message => ({
  type: PROJECT_UPDATE_FAILURE,
  payload: message
});

// Project Selection
export const PROJECT_SELECT_ACTIVE = "PROJECT_SELECT_ACTIVE";
export const selectActiveProject = project => ({
  type: PROJECT_SELECT_ACTIVE,
  payload: project
});

export const PROJECT_ACTIVE_UPDATED = "PROJECT_ACTIVE_UPDATED";
export const updateActiveProject = project => ({
  type: PROJECT_ACTIVE_UPDATED,
  payload: project
});

export const PROJECT_SELECT_CLEAR = "PROJECT_SELECT_CLEAR";
export const projectSelectClear = () => ({
  type: PROJECT_SELECT_CLEAR
});
