import {
  // Fetch
  PROJECT_CHANNEL_GUIDE_ENTRIES_FETCH,
  PROJECT_CHANNEL_GUIDE_ENTRIES_FETCH_SUCCESS,
  PROJECT_CHANNEL_GUIDE_ENTRIES_FETCH_FAILURE,

  // Add
  PROJECT_CHANNEL_GUIDE_ENTRY_ADD,
  PROJECT_CHANNEL_GUIDE_ENTRY_ADD_SUCCESS,
  PROJECT_CHANNEL_GUIDE_ENTRY_ADD_FAILURE,

  // Delete
  PROJECT_CHANNEL_GUIDE_ENTRY_DELETE,
  PROJECT_CHANNEL_GUIDE_ENTRY_DELETE_SUCCESS,
  PROJECT_CHANNEL_GUIDE_ENTRY_DELETE_FAILURE,

  // Update
  PROJECT_CHANNEL_GUIDE_ENTRY_UPDATE,
  PROJECT_CHANNEL_GUIDE_ENTRY_UPDATE_SUCCESS,
  PROJECT_CHANNEL_GUIDE_ENTRY_UPDATE_FAILURE
} from "./actions";

const initialState = {
  fetch: {
    isLoading: true,
    channelGuideEntries: [],
    error: null
  },
  add: {
    success: null,
    isLoading: false,
    error: null
  },
  delete: {
    success: null,
    isLoading: false,
    error: null
  },
  update: {
    success: null,
    isLoading: false,
    error: null
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    // Fetch
    case PROJECT_CHANNEL_GUIDE_ENTRIES_FETCH: {
      return {
        ...state,
        fetch: {
          isLoading: true,
          channelGuideEntries: [],
          error: null
        }
      };
    }
    case PROJECT_CHANNEL_GUIDE_ENTRIES_FETCH_SUCCESS: {
      return {
        ...state,
        fetch: {
          isLoading: false,
          channelGuideEntries: action.payload,
          error: null
        }
      };
    }
    case PROJECT_CHANNEL_GUIDE_ENTRIES_FETCH_FAILURE: {
      return {
        ...state,
        fetch: {
          isLoading: false,
          channelGuideEntries: [],
          error: action.payload
        }
      };
    }

    // Create
    case PROJECT_CHANNEL_GUIDE_ENTRY_ADD: {
      return {
        ...state,
        add: {
          success: null,
          isLoading: true,
          error: null
        }
      };
    }
    case PROJECT_CHANNEL_GUIDE_ENTRY_ADD_SUCCESS: {
      return {
        ...state,
        add: {
          success: action.payload,
          isLoading: false,
          error: null
        }
      };
    }
    case PROJECT_CHANNEL_GUIDE_ENTRY_ADD_FAILURE: {
      return {
        ...state,
        add: {
          success: false,
          isLoading: false,
          error: action.payload
        }
      };
    }

    // Delete
    case PROJECT_CHANNEL_GUIDE_ENTRY_DELETE: {
      return {
        ...state,
        delete: {
          success: null,
          isLoading: true,
          error: null
        }
      };
    }
    case PROJECT_CHANNEL_GUIDE_ENTRY_DELETE_SUCCESS: {
      return {
        ...state,
        delete: {
          success: action.payload,
          isLoading: false,
          error: null
        }
      };
    }
    case PROJECT_CHANNEL_GUIDE_ENTRY_DELETE_FAILURE: {
      return {
        ...state,
        delete: {
          success: false,
          isLoading: false,
          error: action.payload
        }
      };
    }

    // Update
    case PROJECT_CHANNEL_GUIDE_ENTRY_UPDATE: {
      return {
        ...state,
        update: {
          success: null,
          isLoading: true,
          error: null
        }
      };
    }
    case PROJECT_CHANNEL_GUIDE_ENTRY_UPDATE_SUCCESS: {
      return {
        ...state,
        update: {
          success: action.payload,
          isLoading: false,
          error: null
        }
      };
    }
    case PROJECT_CHANNEL_GUIDE_ENTRY_UPDATE_FAILURE: {
      return {
        ...state,
        update: {
          success: false,
          isLoading: false,
          error: action.payload
        }
      };
    }

    default: {
      return state;
    }
  }
}
