import { createStore, applyMiddleware } from "redux";
import { createEpicMiddleware } from "redux-observable";

import rootEpic from "./epics";
import rootReducer from "./reducers";

import firebase from "firebase";
import "firebase/firestore";

firebase.initializeApp({
  apiKey: "AIzaSyAjRcFUeT_o4-0osgOb5vIV_0yl3_agoY4",
  authDomain: "monscierge-platform.firebaseapp.com",
  databaseURL: "https://monscierge-platform.firebaseio.com",
  projectId: "monscierge-platform",
  storageBucket: "monscierge-platform.appspot.com",
  messagingSenderId: "507514328269",
  appId: "1:507514328269:web:a6033dd4ef449a1f954584",
});

const epicMiddleware = createEpicMiddleware();
export default createStore(rootReducer, applyMiddleware(epicMiddleware));
epicMiddleware.run(
  rootEpic(
    firebase.firestore(),
    firebase.auth(),
    new firebase.auth.GoogleAuthProvider()
  )
);
