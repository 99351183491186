import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { map } from "lodash/fp";
import { Typography, Divider, TextField, Avatar } from "@material-ui/core";
import GracenoteStationList from "../gracenote-station-list/gracenote-station-list";

import {
  isLoadingSelector,
  getStationsSelector
} from "../../redux/station-search/selectors";
import { searchGracenoteStations } from "../../redux/station-search/actions";
import useDebounce from "../../hooks/useDebounce";

export function GracenoteSearchStations(props) {
  const {
    isLoading,
    stations,
    searchGracenoteStations,
    onStationSelected
  } = props;

  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  useEffect(() => {
    if (debouncedSearchQuery) {
      console.log("Debounced " + debouncedSearchQuery);
      searchGracenoteStations(debouncedSearchQuery);
    }
  }, [debouncedSearchQuery, searchGracenoteStations]);

  const handleChange = event => {
    setSearchQuery(event.target.value);
  };

  return (
    <div>
      <TextField
        id="outlined-basic"
        fullWidth
        label="Station Search"
        variant="outlined"
        value={searchQuery}
        onChange={handleChange}
      />
      {isLoading && (
        <Typography
          style={{ textAlign: "center", marginTop: 20 }}
          variant="body1"
        >
          Loading Stations...
        </Typography>
      )}
      {!isLoading && stations.length !== 0 && (
        <GracenoteStationList
          stations={stations}
          onStationSelected={station => {
            if (onStationSelected) onStationSelected(station);
          }}
        />
      )}
    </div>
  );
}

export default connect(
  state => ({
    isLoading: isLoadingSelector(state),
    stations: getStationsSelector(state)
  }),
  {
    searchGracenoteStations
  }
)(GracenoteSearchStations);
