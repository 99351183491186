import { from, of, fromEventPattern } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";

import {
  AUTH_LOGIN,
  loginSuccess,
  loginFailure,
  updateAuthStatus
} from "../auth/actions";
import { APP_START } from "../lifecycle/actions";

export const authEpics = (auth, googleProvider) => {
  return {
    loginEpic: action$ =>
      action$.pipe(
        ofType(AUTH_LOGIN),
        switchMap(_action =>
          from(
            auth.signInWithPopup(googleProvider).then(result => ({
              credential: result.credential,
              user: result.user
            }))
          )
        ),
        map(authResult => loginSuccess(authResult)),
        catchError(error => {
          console.error(error);
          of(loginFailure(error.message));
        })
      ),
    fetchLoginOnStart: action$ =>
      action$.pipe(
        ofType(APP_START),
        switchMap(_action =>
          fromEventPattern(
            handler => {
              return auth.onAuthStateChanged(handler);
            },
            (_handler, unsubscribe) => unsubscribe()
          )
        ),
        map(user => updateAuthStatus(user)),
        catchError(error => {
          console.error(error);
        })
      )
  };
};
