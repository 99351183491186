import React from "react";

import map from "lodash/fp/map";

import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Divider
} from "@material-ui/core";

export default function GracenoteStationList(props) {
  const { stations, onStationSelected } = props;

  const makeStationListItems = map(station => (
    <ListItem
      button
      onClick={_event => {
        if (onStationSelected) {
          onStationSelected(station);
        }
      }}
      key={station.stationId}
    >
      <ListItemAvatar>
        <Avatar
          variant="square"
          style={{ marginRight: 20, width: 100, height: 100 }}
          src={station.preferredImage.uri}
        />
      </ListItemAvatar>
      <ListItemText
        disableTypography={true}
        primary={
          <Typography variant="subtitle1" color="textPrimary">
            {station.name}
          </Typography>
        }
        secondary={
          <React.Fragment>
            <Typography variant="body2" color="textPrimary">
              {station.stationId}
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {station.channel} {station.callSign}
            </Typography>
            <Typography variant="body2" color="textPrimary">
              Broadcast Languages :{" "}
              {station && station.bcastLangs && station.bcastLangs.join(", ")}
            </Typography>
            <Typography variant="body2" color="textPrimary">
              Video Quality :{" "}
              {station &&
                station.videoQuality &&
                station.videoQuality.videoType}
            </Typography>
          </React.Fragment>
        }
      />
      <Divider />
    </ListItem>
  ));

  return <List>{makeStationListItems(stations)}</List>;
}
