import React from "react";

import { ListItem, ListItemText, Typography, Divider } from "@material-ui/core";

export default function GracenoteLineupListItem(props) {
  const { lineup, onSelectLineup } = props;

  return (
    <div key={lineup.lineupId}>
      <ListItem
        button
        onClick={() => {
          if (onSelectLineup != undefined) onSelectLineup(lineup);
        }}
      >
        <ListItemText
          disableTypography={true}
          primary={
            <Typography variant="subtitle1" color="textPrimary">
              {lineup.name}
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography variant="body2" color="textPrimary">
                {lineup.lineupId}
              </Typography>
              <Typography variant="body2" color="textPrimary">
                {lineup.location}
              </Typography>
              <Typography variant="body2" color="textPrimary">
                {lineup.type}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider />
    </div>
  );
}
