import { from, of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";

import {
  FETCH_GRACENOTE_LINEUPS,
  SELECT_GRACENOTE_LINEUP,
  fetchGracenoteLineupsSuccess,
  fetchGracenoteLineupsFailure,
} from "../gracenote-lineups/actions";

import { fetchGracenoteChannels } from "../gracenote-channels/actions";

import { gracenoteAPI } from "../../data/gracenote";

const gracenoteClient = gracenoteAPI("r2w33jyvj772syta9gepe7e4");

export const fetchGracenoteLineupsEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_GRACENOTE_LINEUPS),
    switchMap((action) => {
      return from(gracenoteClient.findLineups(action.payload)).pipe(
        map((response) => response.data),
        catchError((error) => {
          console.log(error);
        })
      );
    }),
    map((lineups) => fetchGracenoteLineupsSuccess(lineups)),
    catchError((error) => {
      console.log(error);
      of(fetchGracenoteLineupsFailure(error.message));
    })
  );

export const loadChannelsOnSelectingLineupEpic = (action$) =>
  action$.pipe(
    ofType(SELECT_GRACENOTE_LINEUP),
    switchMap((action) => of(fetchGracenoteChannels(action.payload.lineupId)))
  );
