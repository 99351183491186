import { from, of } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";

import {
  FETCH_GRACENOTE_CHANNELS,
  fetchGracenoteChannelsSuccess,
  fetchGracenoteChannelsFailure,
} from "../gracenote-channels/actions";

import { gracenoteAPI } from "../../data/gracenote";

const gracenoteClient = gracenoteAPI("r2w33jyvj772syta9gepe7e4");

export const fetchGracenoteChannelsEpic = (action$) =>
  action$.pipe(
    ofType(FETCH_GRACENOTE_CHANNELS),
    switchMap((action) => {
      return from(gracenoteClient.lineupChannelList(action.payload)).pipe(
        map((response) => response.data),
        catchError((error) => {
          console.log(error);
        })
      );
    }),
    map((channels) => fetchGracenoteChannelsSuccess(channels)),
    catchError((error) => {
      console.log(error);
      of(fetchGracenoteChannelsFailure(error.message));
    })
  );
