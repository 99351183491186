const countries = [
  {
    value: "USA",
    name: "United States"
  },
  {
    value: "CAN",
    name: "Canada"
  },
  {
    value: "AUS",
    name: "Australia"
  },
  {
    value: "AUT",
    name: "Austria"
  },
  {
    value: "CHE",
    name: "Switzerland"
  },
  {
    value: "DEU",
    name: "Germany"
  },
  {
    value: "DNK",
    name: "Denmark"
  },
  {
    value: "ESP",
    name: "Spain"
  },
  {
    value: "FIN",
    name: "Finland"
  },
  {
    value: "FRA",
    name: "France"
  },
  {
    value: "GBR",
    name: "United Kingdom"
  },
  {
    value: "ITA",
    name: "Italy"
  },
  {
    value: "NOR",
    name: "Norway"
  },
  {
    value: "SWE",
    name: "Sweden"
  },
  {
    value: "ARG",
    name: "Argentina"
  },
  {
    value: "BLZ",
    name: "Belize"
  },
  {
    value: "BRA",
    name: "Brazil"
  },
  {
    value: "CHL",
    name: "Chile"
  },
  {
    value: "COL",
    name: "Colombia"
  },
  {
    value: "CRI",
    name: "Costa Rica"
  },
  {
    value: "ECU",
    name: "Ecuador"
  },
  {
    value: "GTM",
    name: "Guatemala"
  },
  {
    value: "GUY",
    name: "Guyana"
  },
  {
    value: "HND",
    name: "Honduras"
  },
  {
    value: "MEX",
    name: "Mexico"
  },
  {
    value: "PAN",
    name: "Panama"
  },
  {
    value: "PER",
    name: "Peru"
  },
  {
    value: "URY",
    name: "Uruguay"
  },
  {
    value: "VEN",
    name: "Venezuela"
  },
  {
    value: "AIA",
    name: "Anguilla"
  },
  {
    value: "ATG",
    name: "Antigua/Barbuda"
  },
  {
    value: "ABW",
    name: "Aruba"
  },
  {
    value: "BHS",
    name: "Bahamas"
  },
  {
    value: "BRB",
    name: "Barbados"
  },
  {
    value: "BMU",
    name: "Bermuda"
  },
  {
    value: "BES",
    name: "Bonaire, Saba, St. Eustatius"
  },
  {
    value: "VGB",
    name: "British Virgin Islands"
  },
  {
    value: "CYM",
    name: "Cayman Islands"
  },
  {
    value: "CUW",
    name: "Curacao"
  },
  {
    value: "DMA",
    name: "Dominica"
  },
  {
    value: "DOM",
    name: "Dominican Republic"
  },
  {
    value: "GRD",
    name: "Grenada"
  },
  {
    value: "JAM",
    name: "Jamaica"
  },
  {
    value: "PRI",
    name: "Puerto Rico"
  },
  {
    value: "MAF",
    name: "Saint Martin"
  },
  {
    value: "VCT",
    name: "Saint Vincent/Grenadines"
  },
  {
    value: "KNA",
    name: "St. Kitts and Nevis"
  },
  {
    value: "LCA",
    name: "St. Lucia"
  },
  {
    value: "TTO",
    name: "Trinidad and Tobago"
  },
  {
    value: "TCA",
    name: "Turks and Caicos"
  }
];

export default countries;
