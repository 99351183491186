import React, { useState } from "react";
import { connect } from "react-redux";
import map from "lodash/fp/map";

import {
  makeStyles,
  TextField,
  Button,
  Typography,
  List
} from "@material-ui/core";

import CountryComboBox from "./country-combo-box";
import GracenoteLineupListItem from "./gracenote-lineup-list-item";

import {
  getLineupsSelector,
  isLoadingSelector
} from "../../redux/gracenote-lineups/selectors";
import { fetchGracenoteLineups } from "../../redux/gracenote-lineups/actions";

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    paddingTop: 10
  },
  countryCombo: { marginLeft: 20, marginRight: 20 },
  postalCode: {},
  searchButton: {}
}));

export function ProjectGracenoteLineupSearchList(props) {
  const { isLoading, lineups, fetchGracenoteLineups, onSelectLineup } = props;

  const [country, setCountry] = useState(undefined);
  const [postalCode, setPostalCode] = useState(undefined);

  const classes = useStyles();

  const handleSearchButtonClicked = () => {
    if (postalCode != undefined && country != undefined)
      fetchGracenoteLineups({
        country,
        postalCode
      });
  };

  const makeLineupList = map(lineup => (
    <GracenoteLineupListItem
      onSelectLineup={lineup => {
        if (onSelectLineup != undefined) onSelectLineup(lineup);
      }}
      lineup={lineup}
    />
  ));

  return (
    <div className={classes.root}>
      <CountryComboBox
        className={classes.countryCombo}
        onCountrySelect={cc => setCountry(cc)}
      />
      {country != undefined && (
        <div>
          <TextField
            className={classes.postalCode}
            onChange={e => setPostalCode(e.target.value)}
            placeholder="Postal Code"
          />
          <Button
            className={classes.searchButton}
            onClick={handleSearchButtonClicked}
          >
            Search
          </Button>
        </div>
      )}
      {isLoading && <Typography variant="body1">Loading Lineups...</Typography>}
      {lineups && lineups.length !== 0 && (
        <List>{makeLineupList(lineups)}</List>
      )}
    </div>
  );
}

export default connect(
  state => ({
    lineups: getLineupsSelector(state),
    isLoading: isLoadingSelector(state)
  }),
  {
    fetchGracenoteLineups
  }
)(ProjectGracenoteLineupSearchList);
