import {
  // Fetch
  PROJECTS_FETCH,
  PROJECTS_FETCH_SUCCESS,
  PROJECTS_FETCH_FAILURE,
  // Create
  PROJECT_CREATE,
  PROJECT_CREATE_SUCCESS,
  PROJECT_CREATE_FAILURE,
  // Delete
  PROJECT_DELETE,
  PROJECT_DELETE_SUCCESS,
  PROJECT_DELETE_FAILURE,
  // Update
  PROJECT_UPDATE,
  PROJECT_UPDATE_SUCCESS,
  PROJECT_UPDATE_FAILURE,
  // Select
  PROJECT_SELECT_ACTIVE,
  PROJECT_SELECT_CLEAR,
  PROJECT_ACTIVE_UPDATED
} from "./actions";

const initialstate = {
  fetchProjects: {
    projects: [],
    isLoading: false,
    error: null
  },
  createProject: {
    success: null,
    isLoading: false,
    error: null
  },
  deleteProject: {
    success: null,
    isLoading: false,
    error: null
  },
  updateProject: {
    success: null,
    isLoading: false,
    error: null
  },
  selectProject: {
    activeProject: null
  }
};

export default function(state = initialstate, action) {
  switch (action.type) {
    // Fetch
    case PROJECTS_FETCH: {
      return {
        ...state,
        fetchProjects: {
          ...state.fetchProjects,
          isLoading: true,
          error: null
        }
      };
    }
    case PROJECTS_FETCH_SUCCESS: {
      return {
        ...state,
        fetchProjects: {
          projects: [...action.payload],
          isLoading: false,
          error: null
        }
      };
    }
    case PROJECTS_FETCH_FAILURE: {
      return {
        ...state,
        fetchProjects: {
          projects: [],
          isLoading: false,
          error: action.payload
        }
      };
    }

    // Create
    case PROJECT_CREATE: {
      return {
        ...state,
        createProject: {
          ...state.createProject,
          isLoading: true,
          error: null
        }
      };
    }
    case PROJECT_CREATE_SUCCESS: {
      return {
        ...state,
        createProject: {
          success: action.payload,
          isLoading: false,
          error: null
        }
      };
    }
    case PROJECT_CREATE_FAILURE: {
      return {
        ...state,
        createProject: {
          success: false,
          isLoading: false,
          error: action.payload
        }
      };
    }

    // Delete
    case PROJECT_DELETE: {
      return {
        ...state,
        deleteProject: {
          ...state.deleteProject,
          isLoading: true,
          error: null
        }
      };
    }
    case PROJECT_DELETE_SUCCESS: {
      return {
        ...state,
        deleteProject: {
          success: action.payload,
          isLoading: false,
          error: null
        }
      };
    }
    case PROJECT_DELETE_FAILURE: {
      return {
        ...state,
        deleteProject: {
          success: false,
          isLoading: false,
          error: action.payload
        }
      };
    }

    // Update
    case PROJECT_UPDATE: {
      return {
        ...state,
        updateProject: {
          ...state.updateProject,
          isLoading: true,
          error: null
        }
      };
    }
    case PROJECT_UPDATE_SUCCESS: {
      return {
        ...state,
        updateProject: {
          success: action.payload,
          isLoading: false,
          error: null
        }
      };
    }
    case PROJECT_UPDATE_FAILURE: {
      return {
        ...state,
        updateProject: {
          success: false,
          isLoading: false,
          error: action.payload
        }
      };
    }

    // Select
    case PROJECT_SELECT_ACTIVE: {
      return {
        ...state,
        selectProject: {
          activeProject: action.payload
        }
      };
    }
    case PROJECT_ACTIVE_UPDATED: {
      return {
        ...state,
        selectProject: {
          activeProject: action.payload
        }
      };
    }
    case PROJECT_SELECT_CLEAR: {
      return {
        ...state,
        selectProject: {
          activeProject: null
        }
      };
    }

    default: {
      return state;
    }
  }
}
