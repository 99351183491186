// Lineups API

export const FETCH_GRACENOTE_LINEUPS = "FETCH_GRACENOTE_LINEUPS";
export const FETCH_GRACENOTE_LINEUPS_SUCCESS =
  "FETCH_GRACENOTE_LINEUPS_SUCCESS";
export const FETCH_GRACENOTE_LINEUPS_FAILURE =
  "FETCH_GRACENOTE_LINEUPS_FAILURE";

export const fetchGracenoteLineups = ({ country, postalCode }) => ({
  type: FETCH_GRACENOTE_LINEUPS,
  payload: { country, postalCode }
});

export const fetchGracenoteLineupsSuccess = lineups => ({
  type: FETCH_GRACENOTE_LINEUPS_SUCCESS,
  payload: lineups
});

export const fetchGracenoteLineupsFailure = message => ({
  type: FETCH_GRACENOTE_LINEUPS_FAILURE,
  payload: message
});

// Search Filters

// Selected

export const SELECT_GRACENOTE_LINEUP = "SELECT_GRACENOTE_LINEUP";

export const selectGracenoteLineup = lineup => ({
  type: SELECT_GRACENOTE_LINEUP,
  payload: lineup
});
