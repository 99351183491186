import React from "react";
import { map } from "lodash/fp";
import { List, Paper } from "@material-ui/core";

import ProjectListItem from "./project-list-item";

export default function ProjectList(props) {
  const { projects, onProjectClick, isEditable, onProjectDelete } = props;

  const projectList = map(project =>
    ProjectListItem({
      project,
      isEditable: isEditable,
      onDelete: onProjectDelete,
      onClick: _event => {
        onProjectClick(project);
      }
    })
  );

  return <List>{projectList(projects)}</List>;
}
