import {
  // Fetch
  PROJECT_CHANNEL_GUIDES_FETCH_SUCCESS,

  // Add
  PROJECT_CHANNEL_GUIDE_ADD,
  PROJECT_CHANNEL_GUIDE_ADD_SUCCESS,
  PROJECT_CHANNEL_GUIDE_ADD_FAILURE,
  PROJECT_CHANNEL_GUIDE_SELECT_ACTIVE,
  PROJECT_CHANNEL_GUIDE_SELECT_CLEAR,

  // Delete
  PROJECT_CHANNEL_GUIDE_DELETE,
  PROJECT_CHANNEL_GUIDE_DELETE_SUCCESS,
  PROJECT_CHANNEL_GUIDE_DELETE_FAILURE,
  PROJECT_CHANNEL_GUIDE_PUBLISH,
  PROJECT_CHANNEL_GUIDE_PUBLISH_SUCCESS,
  PROJECT_CHANNEL_GUIDE_PUBLISH_FAILURE
} from "./actions";

const initialState = {
  fetch: {
    isLoading: true,
    channelGuides: [],
    error: null
  },
  add: {
    success: null,
    isLoading: false,
    error: null
  },
  delete: {
    success: null,
    isLoading: false,
    error: null
  },
  publish: {
    success: null,
    isLoading: false,
    error: null
  },
  select: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    // Fetch
    case PROJECT_CHANNEL_GUIDES_FETCH_SUCCESS: {
      return {
        ...state,
        fetch: {
          isLoading: false,
          channelGuides: action.payload
        }
      };
    }

    // Create
    case PROJECT_CHANNEL_GUIDE_ADD: {
      return {
        ...state,
        add: {
          success: null,
          isLoading: true,
          error: null
        }
      };
    }
    case PROJECT_CHANNEL_GUIDE_ADD_SUCCESS: {
      return {
        ...state,
        add: {
          success: action.payload,
          isLoading: false,
          error: null
        }
      };
    }
    case PROJECT_CHANNEL_GUIDE_ADD_FAILURE: {
      return {
        ...state,
        add: {
          success: false,
          isLoading: false,
          error: action.payload
        }
      };
    }

    // Delete
    case PROJECT_CHANNEL_GUIDE_DELETE: {
      return {
        ...state,
        delete: {
          success: null,
          isLoading: true,
          error: null
        }
      };
    }
    case PROJECT_CHANNEL_GUIDE_DELETE_SUCCESS: {
      return {
        ...state,
        delete: {
          success: action.payload,
          isLoading: false,
          error: null
        }
      };
    }
    case PROJECT_CHANNEL_GUIDE_DELETE_FAILURE: {
      return {
        ...state,
        delete: {
          success: false,
          isLoading: false,
          error: action.payload
        }
      };
    }

    // Select
    case PROJECT_CHANNEL_GUIDE_SELECT_ACTIVE: {
      return {
        ...state,
        select: action.payload
      };
    }

    case PROJECT_CHANNEL_GUIDE_SELECT_CLEAR: {
      return {
        ...state,
        select: null
      };
    }

    // Publish
    case PROJECT_CHANNEL_GUIDE_PUBLISH: {
      return {
        ...state,
        publish: {
          success: null,
          isLoading: true,
          error: null
        }
      };
    }
    case PROJECT_CHANNEL_GUIDE_PUBLISH_SUCCESS: {
      return {
        ...state,
        publish: {
          success: action.payload,
          isLoading: false,
          error: null
        }
      };
    }
    case PROJECT_CHANNEL_GUIDE_PUBLISH_FAILURE: {
      return {
        ...state,
        publish: {
          success: false,
          isLoading: false,
          error: action.payload
        }
      };
    }

    default: {
      return state;
    }
  }
}
