import { from, of, fromEventPattern } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";
import loMap from "lodash/fp/map";
import { ofType } from "redux-observable";

import firebase from "firebase";

import {
  PROJECTS_FETCH,
  PROJECT_SELECT_ACTIVE,
  fetchProjectsSuccess,
  fetchProjectsFailure,
  updateActiveProject,
  PROJECT_CREATE,
  PROJECT_DELETE,
  createProjectSuccess,
  createProjectFailure,
  deleteProjectSuccess,
  deleteProjectFailure
} from "../projects/actions";
import {
  PROJECT_GRACENOTE_LINEUP_ADD,
  addProjectGracenoteLineupSuccess,
  addProjectGracenoteLineupFailure,
  PROJECT_GRACENOTE_LINEUP_DELETE,
  deleteProjectGracenoteLineupSuccess,
  deleteProjectGracenoteLineupFailure
} from "../project-workspace/gracenote-lineups/actions";
import {
  fetchProjectChannelGuidesSuccess,
  PROJECT_CHANNEL_GUIDE_ADD,
  PROJECT_CHANNEL_GUIDE_SELECT_ACTIVE,
  addProjectChannelGuideSuccess,
  addProjectChannelGuideFailure,
  PROJECT_CHANNEL_GUIDE_DELETE,
  deleteProjectChannelGuideSuccess,
  deleteProjectChannelGuideFailure,
  PROJECT_CHANNEL_GUIDE_PUBLISH,
  publishProjectChannelGuideFailure,
  publishProjectChannelGuideSuccess
} from "../project-workspace/channel-guides/actions";
import {
  fetchProjectChannelGuideEntriesSuccess,
  PROJECT_CHANNEL_GUIDE_ENTRY_ADD,
  addProjectChannelGuideEntrySuccess,
  addProjectChannelGuideEntryFailure,
  PROJECT_CHANNEL_GUIDE_ENTRY_DELETE,
  deleteProjectChannelGuideEntryFailure,
  updateProjectChannelGuideEntrySuccess,
  deleteProjectChannelGuideEntrySuccess,
  PROJECT_CHANNEL_GUIDE_ENTRY_UPDATE,
  updateProjectChannelGuideEntryFailure
} from "../project-workspace/channel-guides/entries/actions";

const COLLECTION_PROJECTS = "ChannelGuideProjects";
const COLLECTION_PROJECT_CHANNEL_GUIDES = "ProjectChannelGuides";

export const projectsEpics = db => {
  return {
    fetchProjectsEpic: action$ =>
      action$.pipe(
        ofType(PROJECTS_FETCH),
        switchMap(_action => {
          return fromEventPattern(
            handler =>
              db
                .collection(COLLECTION_PROJECTS)
                .orderBy("name")
                .onSnapshot(handler),
            (_handler, unsubscribe) => unsubscribe()
          );
        }),
        map(projects =>
          fetchProjectsSuccess(
            projects.docs.map(doc => ({ id: doc.id, ...doc.data() }))
          )
        ),
        catchError(error => {
          console.error(error);
        })
      ),
    selectProjectEpic: action$ =>
      action$.pipe(
        ofType(PROJECT_SELECT_ACTIVE),
        switchMap(action =>
          fromEventPattern(
            handler => {
              return db
                .collection(COLLECTION_PROJECTS)
                .doc(action && action && action.payload)
                .onSnapshot(handler);
            },
            (_handler, unsubscribe) => unsubscribe()
          )
        ),
        map(project =>
          updateActiveProject({ id: project.id, ...project.data() })
        ),
        catchError(error => {
          console.error(error);
        })
      ),
    createProjectEpic: action$ =>
      action$.pipe(
        ofType(PROJECT_CREATE),
        switchMap(action => {
          return from(
            db
              .collection(COLLECTION_PROJECTS)
              .add(action.payload)
              .then(() => true)
          );
        }),
        map(result => createProjectSuccess(result)),
        catchError(error => {
          of(createProjectFailure(error.message));
        })
      ),
    deleteProjectEpic: action$ =>
      action$.pipe(
        ofType(PROJECT_DELETE),
        switchMap(action =>
          from(
            (async () => {
              await db
                .collection(COLLECTION_PROJECTS)
                .doc(action.payload.projectId)
                .delete();
            })().then(() => true)
          )
        ),
        map(projects => deleteProjectSuccess(projects)),
        catchError(error => {
          console.error(error);
          of(deleteProjectFailure(error.message));
        })
      )
  };
};

export const projectGracenoteLineups = db => ({
  addLineup: action$ =>
    action$.pipe(
      ofType(PROJECT_GRACENOTE_LINEUP_ADD),
      switchMap(action => {
        return from(
          db
            .collection(COLLECTION_PROJECTS)
            .doc(action && action.payload && action.payload.projectId)
            .update({
              gracenoteLineups: firebase.firestore.FieldValue.arrayUnion(
                action.payload.lineup
              )
            })
            .then(() => true)
        );
      }),
      map(() => addProjectGracenoteLineupSuccess(true)),
      catchError(error => {
        console.error(error);
        of(addProjectGracenoteLineupFailure(error.message));
      })
    ),
  deleteLineup: action$ =>
    action$.pipe(
      ofType(PROJECT_GRACENOTE_LINEUP_DELETE),
      switchMap(action => {
        return from(
          (async () => {
            const project = await db
              .collection(COLLECTION_PROJECTS)
              .doc(action && action.payload && action.payload.projectId)
              .get();

            await db
              .collection(COLLECTION_PROJECTS)
              .doc(action && action.payload && action.payload.projectId)
              .update({
                gracenoteLineups: project
                  .data()
                  .gracenoteLineups.filter(
                    lineup => lineup.lineupId !== action.payload.lineupId
                  )
              });
          })().then(() => true)
        );
      }),
      map(() => deleteProjectGracenoteLineupSuccess(true)),
      catchError(error => {
        console.error(error);
        of(deleteProjectGracenoteLineupFailure(error.message));
      })
    )
});

export const projectChannelGuides = db => ({
  fetchChannelGuides: action$ =>
    action$.pipe(
      ofType(PROJECT_SELECT_ACTIVE),
      switchMap(action => {
        return fromEventPattern(
          handler => {
            return db
              .collection(COLLECTION_PROJECT_CHANNEL_GUIDES)
              .doc(action && action.payload)
              .collection("channelGuides")
              .onSnapshot(handler);
          },
          (_handler, unsubscribe) => unsubscribe()
        );
      }),
      map(channelGuideRefs => {
        return fetchProjectChannelGuidesSuccess(
          channelGuideRefs.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        );
      }),
      catchError(error => {
        console.error(error);
      })
    ),
  addChannelGuide: action$ =>
    action$.pipe(
      ofType(PROJECT_CHANNEL_GUIDE_ADD),
      switchMap(action => {
        return from(
          db
            .collection(COLLECTION_PROJECT_CHANNEL_GUIDES)
            .doc(action && action.payload && action.payload.projectId)
            .collection("channelGuides")
            .add(action.payload.channelGuide)
            .then(docRef =>
              db
                .collection(COLLECTION_PROJECTS)
                .doc(action.payload.projectId)
                .update({
                  channelGuides: firebase.firestore.FieldValue.arrayUnion(
                    docRef
                  )
                })
            )
            .then(() => true)
        );
      }),
      map(() => addProjectChannelGuideSuccess(true)),
      catchError(error => {
        console.error(error);
        of(addProjectChannelGuideFailure(error.message));
      })
    ),
  deleteChannelGuide: action$ =>
    action$.pipe(
      ofType(PROJECT_CHANNEL_GUIDE_DELETE),
      switchMap(action => {
        return from(
          (async () => {
            await db
              .collection(COLLECTION_PROJECT_CHANNEL_GUIDES)
              .doc(action && action.payload && action.payload.projectId)
              .collection("channelGuides")
              .doc(action.payload.channelGuideId)
              .delete();
          })().then(() => true)
        );
      }),
      map(() => deleteProjectChannelGuideSuccess(true)),
      catchError(error => {
        console.error(error);
        of(deleteProjectChannelGuideFailure(error.message));
      })
    ),
  publishChannelGuide: action$ =>
    action$.pipe(
      ofType(PROJECT_CHANNEL_GUIDE_PUBLISH),
      switchMap(action => {
        return from(
          (async () => {
            const channelGuideRef = await db
              .collection(COLLECTION_PROJECT_CHANNEL_GUIDES)
              .doc(action && action.payload && action.payload.projectId)
              .collection("channelGuides")
              .doc(action.payload.channelGuideId)
              .get();

            const channelGuide = {
              id: channelGuideRef.id,
              ...channelGuideRef.data()
            };

            const channelGuideEntryRefs = (
              await db
                .collection(COLLECTION_PROJECT_CHANNEL_GUIDES)
                .doc(action && action.payload && action.payload.projectId)
                .collection("channelGuides")
                .doc(action.payload.channelGuideId)
                .collection("entries")
                .get()
            ).docs;

            const channels = loMap(entry => ({
              mrl: entry.mrl ? entry.mrl : null,
              name: entry.affiliateCallSign || entry.callSign,
              ordinal: entry.ordinal,
              provider: {
                provider: "Gracenote",
                stationId: entry.stationId || 0
              }
            }))(
              loMap(entryRef => ({ id: entryRef.id, ...entryRef.data() }))(
                channelGuideEntryRefs
              )
            ).sort((a, b) => {
              if (Number(a.ordinal) < Number(b.ordinal)) {
                return -1;
              }
              if (Number(a.ordinal) > Number(b.ordinal)) {
                return 1;
              }
              // a must be equal to b
              return 0;
            });

            const saveChannelGuide = {
              id: channelGuide.id,
              channels,
              name: channelGuide.name
            };
            try {
            await db
              .collection("ChannelGuide")
              .doc(channelGuide.id)
              .set(saveChannelGuide);
            }
            catch (exception) {
              console.log(exception);
              console.log(JSON.stringify(saveChannelGuide));
            }
          })().then(() => true)
        );
      }),
      map(() => publishProjectChannelGuideSuccess(true)),
      catchError(error => {
        console.error(error);
        of(publishProjectChannelGuideFailure(error.message));
      })
    )
});

export const projectChannelGuideEntries = db => ({
  fetchChannelGuideEntries: action$ =>
    action$.pipe(
      ofType(PROJECT_CHANNEL_GUIDE_SELECT_ACTIVE),
      switchMap(action => {
        return fromEventPattern(
          handler => {
            return db
              .collection(COLLECTION_PROJECT_CHANNEL_GUIDES)
              .doc(action && action.payload.projectId)
              .collection("channelGuides")
              .doc(action.payload.channelGuideId)
              .collection("entries")
              .onSnapshot(handler);
          },
          (_handler, unsubscribe) => unsubscribe()
        );
      }),
      map(channelGuideRef =>
        fetchProjectChannelGuideEntriesSuccess(
          channelGuideRef.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        )
      )
    ),
  addChannelGuideEntry: action$ =>
    action$.pipe(
      ofType(PROJECT_CHANNEL_GUIDE_ENTRY_ADD),
      switchMap(action => {
        return from(
          (async () => {
            const channelGuide = await db
              .collection(COLLECTION_PROJECT_CHANNEL_GUIDES)
              .doc(action && action.payload.projectId)
              .collection("channelGuides")
              .doc(action.payload.channelGuideId)
              .get();

            const nextOrdinal = channelGuide.data().nextOrdinal;

            await db
              .collection(COLLECTION_PROJECT_CHANNEL_GUIDES)
              .doc(action && action.payload.projectId)
              .collection("channelGuides")
              .doc(action.payload.channelGuideId)
              .collection("entries")
              .add({
                ...action.payload.channelGuideEntry,
                ordinal: nextOrdinal
              });

            await db
              .collection(COLLECTION_PROJECT_CHANNEL_GUIDES)
              .doc(action && action.payload.projectId)
              .collection("channelGuides")
              .doc(action.payload.channelGuideId)
              .update({ nextOrdinal: nextOrdinal + 1 });
          })().then(() => true)
        );
      }),
      map(() => addProjectChannelGuideEntrySuccess(true)),
      catchError(error => {
        console.error(error);
        of(addProjectChannelGuideEntryFailure(error.message));
      })
    ),
  deleteChannelGuideEntry: action$ =>
    action$.pipe(
      ofType(PROJECT_CHANNEL_GUIDE_ENTRY_DELETE),
      switchMap(action => {
        return from(
          (async () => {
            await db
              .collection(COLLECTION_PROJECT_CHANNEL_GUIDES)
              .doc(action && action.payload.projectId)
              .collection("channelGuides")
              .doc(action.payload.channelGuideId)
              .collection("entries")
              .doc(action.payload.channelGuideEntryId)
              .delete();

            const entriesSnapshot = await db
              .collection(COLLECTION_PROJECT_CHANNEL_GUIDES)
              .doc(action && action.payload.projectId)
              .collection("channelGuides")
              .doc(action.payload.channelGuideId)
              .collection("entries")
              .orderBy("ordinal")
              .get();

            const entries = loMap(doc => ({
              id: doc.id,
              ...doc.data()
            }))(entriesSnapshot.docs);

            const updates = entries.map((entry, index) =>
              db
                .collection(COLLECTION_PROJECT_CHANNEL_GUIDES)
                .doc(action && action.payload.projectId)
                .collection("channelGuides")
                .doc(action.payload.channelGuideId)
                .collection("entries")
                .doc(entry.id)
                .update({
                  ordinal: index
                })
            );

            await Promise.all(updates);

            await db
              .collection(COLLECTION_PROJECT_CHANNEL_GUIDES)
              .doc(action && action.payload.projectId)
              .collection("channelGuides")
              .doc(action.payload.channelGuideId)
              .update({ nextOrdinal: entries.length });
          })().then(() => true)
        );
      }),
      map(() => deleteProjectChannelGuideEntrySuccess(true)),
      catchError(error => {
        console.error(error);
        of(deleteProjectChannelGuideEntryFailure(error.message));
      })
    ),
  updateChannelGuideEntry: action$ =>
    action$.pipe(
      ofType(PROJECT_CHANNEL_GUIDE_ENTRY_UPDATE),
      switchMap(action => {
        return from(
          db
            .collection(COLLECTION_PROJECT_CHANNEL_GUIDES)
            .doc(action && action.payload.projectId)
            .collection("channelGuides")
            .doc(action.payload.channelGuideId)
            .collection("entries")
            .doc(action.payload.channelGuideEntryId)
            .update(action.payload.channelGuideEntry)
            .then(() => true)
        );
      }),
      map(() => updateProjectChannelGuideEntrySuccess(true)),
      catchError(error => {
        console.error(error);
        of(updateProjectChannelGuideEntryFailure(error.message));
      })
    )
});
