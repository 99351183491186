import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import countries from "./countries.constant";

export default function CountryComboBox(props) {
  const { className, onCountrySelect } = props;

  function handleOptionSelect(_e, value) {
    if (onCountrySelect) onCountrySelect(value.value);
  }

  return (
    <Autocomplete
      id="country-combo-box"
      options={countries}
      onChange={handleOptionSelect}
      getOptionLabel={option => option.name}
      className={className}
      renderInput={params => (
        <TextField
          {...params}
          label="Select Country"
          variant="outlined"
          fullWidth
        />
      )}
    />
  );
}
