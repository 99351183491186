import { combineEpics } from "redux-observable";
import {
  fetchGracenoteLineupsEpic,
  loadChannelsOnSelectingLineupEpic
} from "./gracenote-lineups";
import { fetchGracenoteChannelsEpic } from "./gracenote-channels";
import { searchGracenoteStationsEpic } from "./station-search";
import {
  projectsEpics,
  projectGracenoteLineups,
  projectChannelGuides,
  projectChannelGuideEntries
} from "./projects";
import { authEpics } from "./auth";

export default function epics(db, auth, googleProvider) {
  const {
    fetchProjectsEpic,
    selectProjectEpic,
    createProjectEpic,
    deleteProjectEpic
  } = projectsEpics(db);
  const {
    addChannelGuide,
    fetchChannelGuides,
    deleteChannelGuide,
    publishChannelGuide
  } = projectChannelGuides(db);
  const {
    addChannelGuideEntry,
    fetchChannelGuideEntries,
    deleteChannelGuideEntry,
    updateChannelGuideEntry
  } = projectChannelGuideEntries(db);
  const { addLineup, deleteLineup } = projectGracenoteLineups(db);

  const { loginEpic, fetchLoginOnStart } = authEpics(auth, googleProvider);
  return combineEpics(
    loginEpic,
    fetchLoginOnStart,
    fetchGracenoteLineupsEpic,
    addChannelGuide,
    fetchChannelGuides,
    deleteChannelGuide,
    publishChannelGuide,
    // fetchGracenoteLineupsOnStartEpic,
    fetchGracenoteChannelsEpic,
    loadChannelsOnSelectingLineupEpic,
    searchGracenoteStationsEpic,
    fetchProjectsEpic,
    selectProjectEpic,
    createProjectEpic,
    deleteProjectEpic,
    addLineup,
    deleteLineup,
    addChannelGuideEntry,
    fetchChannelGuideEntries,
    deleteChannelGuideEntry,
    updateChannelGuideEntry
  );
}
