// Project Workspace Operations

// start Gracenote Lineups

// Project Gracenote Lineup Add
export const PROJECT_GRACENOTE_LINEUP_ADD = "PROJECT_GRACENOTE_LINEUP_ADD";
export const PROJECT_GRACENOTE_LINEUP_ADD_SUCCESS =
  "PROJECT_GRACENOTE_LINEUP_ADD_SUCCESS";
export const PROJECT_GRACENOTE_LINEUP_ADD_FAILURE =
  "PROJECT_GRACENOTE_LINEUP_ADD_FAILURE";
export const addProjectGracenoteLineup = options => {
  return {
    type: PROJECT_GRACENOTE_LINEUP_ADD,
    payload: options
  };
};
export const addProjectGracenoteLineupSuccess = success => ({
  type: PROJECT_GRACENOTE_LINEUP_ADD_SUCCESS,
  payload: success
});
export const addProjectGracenoteLineupFailure = message => ({
  type: PROJECT_GRACENOTE_LINEUP_ADD_FAILURE,
  payload: message
});

// Project Gracenote Lineup Delete
export const PROJECT_GRACENOTE_LINEUP_DELETE =
  "PROJECT_GRACENOTE_LINEUP_DELETE";
export const PROJECT_GRACENOTE_LINEUP_DELETE_SUCCESS =
  "PROJECT_GRACENOTE_LINEUP_DELETE_SUCCESS";
export const PROJECT_GRACENOTE_LINEUP_DELETE_FAILURE =
  "PROJECT_GRACENOTE_LINEUP_DELETE_FAILURE";
export const deleteProjectGracenoteLineup = options => ({
  type: PROJECT_GRACENOTE_LINEUP_DELETE,
  payload: options
});
export const deleteProjectGracenoteLineupSuccess = success => ({
  type: PROJECT_GRACENOTE_LINEUP_DELETE_SUCCESS,
  payload: success
});
export const deleteProjectGracenoteLineupFailure = message => ({
  type: PROJECT_GRACENOTE_LINEUP_DELETE_FAILURE,
  payload: message
});

// start Gracenote Assets
// Project Gracenote Assets Fetch
export const PROJECT_GRACENOTE_ASSET_FETCH = "PROJECT_GRACENOTE_ASSET_FETCH";
export const PROJECT_GRACENOTE_ASSET_FETCH_SUCCESS =
  "PROJECT_GRACENOTE_ASSET_FETCH_SUCCESS";
export const PROJECT_GRACENOTE_ASSET_FETCH_FAILURE =
  "PROJECT_GRACENOTE_ASSET_FETCH_FAILURE";
export const fetchProjectGracenoteAssets = projectId => {
  return {
    type: PROJECT_GRACENOTE_ASSET_FETCH,
    payload: projectId
  };
};
export const fetchProjectGracenoteAssetsSuccess = success => ({
  type: PROJECT_GRACENOTE_ASSET_FETCH_SUCCESS,
  payload: success
});
export const fetchProjectGracenoteAssetsFailure = message => ({
  type: PROJECT_GRACENOTE_ASSET_FETCH_FAILURE,
  payload: message
});

// Project Gracenote Lineup Save
export const PROJECT_GRACENOTE_ASSET_SAVE = "PROJECT_GRACENOTE_ASSET_SAVE";
export const PROJECT_GRACENOTE_ASSET_SAVE_SUCCESS =
  "PROJECT_GRACENOTE_ASSET_SAVE_SUCCESS";
export const PROJECT_GRACENOTE_ASSET_SAVE_FAILURE =
  "PROJECT_GRACENOTE_ASSET_SAVE_FAILURE";
export const saveProjectGracenoteAsset = options => {
  return {
    type: PROJECT_GRACENOTE_ASSET_SAVE,
    payload: options
  };
};
export const saveProjectGracenoteAssetSuccess = success => ({
  type: PROJECT_GRACENOTE_ASSET_SAVE_SUCCESS,
  payload: success
});
export const saveProjectGracenoteAssetFailure = message => ({
  type: PROJECT_GRACENOTE_ASSET_SAVE_FAILURE,
  payload: message
});

// Project Gracenote Lineup Delete
export const PROJECT_GRACENOTE_ASSET_DELETE = "PROJECT_GRACENOTE_ASSET_DELETE";
export const PROJECT_GRACENOTE_ASSET_DELETE_SUCCESS =
  "PROJECT_GRACENOTE_ASSET_DELETE_SUCCESS";
export const PROJECT_GRACENOTE_ASSET_DELETE_FAILURE =
  "PROJECT_GRACENOTE_ASSET_DELETE_FAILURE";
export const deleteProjectGracenoteAsset = options => ({
  type: PROJECT_GRACENOTE_ASSET_DELETE,
  payload: options
});
export const deleteProjectGracenoteAssetSuccess = success => ({
  type: PROJECT_GRACENOTE_ASSET_DELETE_SUCCESS,
  payload: success
});
export const deleteProjectGracenoteAssetFailure = message => ({
  type: PROJECT_GRACENOTE_ASSET_DELETE_FAILURE,
  payload: message
});
