// Login
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAILURE = "AUTH_LOGIN_FAILURE";
export const login = auth => {
  return {
    type: AUTH_LOGIN,
    payload: auth
  };
};
export const loginSuccess = authResult => ({
  type: AUTH_LOGIN_SUCCESS,
  payload: authResult
});
export const loginFailure = message => ({
  type: AUTH_LOGIN_FAILURE,
  payload: message
});

// Logout
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const logout = () => ({
  type: AUTH_LOGOUT
});

export const AUTH_STATUS_UPDATED = "AUTH_STATUS_UPDATED";
export const updateAuthStatus = user => ({
  type: AUTH_STATUS_UPDATED,
  payload: user
});
