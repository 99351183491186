// Lineups API

export const SEARCH_GRACENOTE_STATIONS = "SEARCH_GRACENOTE_STATIONS";
export const SEARCH_GRACENOTE_STATIONS_SUCCESS = "SEARCH_GRACENOTE_STATIONS_SUCCESS";
export const SEARCH_GRACENOTE_STATIONS_FAILURE = "SEARCH_GRACENOTE_STATIONS_FAILURE";

export const searchGracenoteStations = (query) => {
  console.log("Query " + query)
  return {
    type: SEARCH_GRACENOTE_STATIONS,
    payload: { query }
  }
};

export const searchGracenoteStationsSuccess = (stations) => ({
  type: SEARCH_GRACENOTE_STATIONS_SUCCESS,
  payload: stations
});

export const searchGracenoteStationsFailure = (message) => ({
  type: SEARCH_GRACENOTE_STATIONS_FAILURE,
  payload: message
});