import { get } from "lodash/fp";

export const projectChannelGuideEntries = {
  fetchProjectChannelGuideEntries: state =>
    get("projectChannelGuideEntries.fetch.channelGuideEntries")(state)
};
// onst initialstate = {
//   fetchProjects: {
//     projects: [],
//     isLoading: false,
//     error: null
//   },
//   createProject: {
//     success: null,
//     isLoading: false,
//     error: null
//   },
//   deleteProject: {
//     success: null,
//     isLoading: false,
//     error: null
//   },
//   updateProject: {
//     success: null,
//     isLoading: false,
//     error: null
//   },
//   selectProject: {
//     activeProject: null
//   }
// };
