import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  makeStyles,
  Typography,
  Drawer,
  Tabs,
  Tab,
  AppBar,
  IconButton,
  Box,
  Button,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { gracenoteAPI } from "../../data/gracenote";

import { select } from "../../redux/projects/selectors";
import { selectActiveProject } from "../../redux/projects/actions";
import {
  addProjectGracenoteLineup,
  deleteProjectGracenoteLineup,
} from "../../redux/project-workspace/gracenote-lineups/actions";
import {
  addProjectChannelGuide,
  publishProjectChannelGuide,
  selectActiveProjectChannelGuide,
  projectChannelGuideSelectClear,
  deleteProjectChannelGuide,
} from "../../redux/project-workspace/channel-guides/actions";
import {
  addProjectChannelGuideEntry,
  updateProjectChannelGuideEntry,
  deleteProjectChannelGuideEntry,
} from "../../redux/project-workspace/channel-guides/entries/actions";
import { projectChannelGuides } from "../../redux/project-workspace/channel-guides/selectors";

import ProjectGracenoteLineupSearchList from "../gracenote-lineup-search/project-gracenote-lineup-search-list";
import { ProjectGracenoteLineupList } from "../project-gracenote-lineup-list/project-gracenote-lineup-list";
import GracenoteStationList from "../gracenote-station-list/gracenote-station-list";
import GracenoteSearchStations from "../gracenote-station-search-list/gracenote-station-search-list";
import ChannelGuideComposer from "../channel-guide-composer";
import ProjectChannelGuideEditList from "./project-channel-guides-list";
import { projectChannelGuideEntries } from "../../redux/project-workspace/channel-guides/entries/selectors";

const drawerWidth = 500;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 10,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  formDrawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: theme.zIndex.drawer + 2,
  },
  toolbar: theme.mixins.toolbar,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function ProjectGracenoteLineupStationList(props) {
  const { lineupId, addChannelGuideEntry } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [stations, setStations] = useState(null);

  useEffect(() => {
    gracenoteAPI("r2w33jyvj772syta9gepe7e4")
      .lineupChannelList(lineupId)
      .then((response) => {
        setIsLoading(false);
        setStations(response.data);
      });
  }, [lineupId]);

  return (
    <Box>
      {isLoading && <Typography>Loading Stations</Typography>}
      {!isLoading && stations && (
        <GracenoteStationList
          onStationSelected={addChannelGuideEntry}
          stations={stations}
        />
      )}
    </Box>
  );
}

function ProjectGracenoteLineupSelection(props) {
  const {
    gracenoteLineups,
    projectId,
    addProjectGracenoteLineup,
    addChannelGuideEntry,
    deleteProjectGracenoteLineup,
  } = props;

  const classes = useStyles();
  const [formShow, setFormShow] = useState(false);
  const [selectedLineup, setSelectedLineup] = useState(null);
  const [lineupListIsEditable, setLineupListIsEditable] = useState(false);

  return (
    <div>
      {selectedLineup == null && (
        <Box>
          {!lineupListIsEditable && (
            <IconButton onClick={() => setFormShow(true)}>
              <AddIcon />
            </IconButton>
          )}
          <Button
            onClick={() => setLineupListIsEditable(!lineupListIsEditable)}
          >
            {lineupListIsEditable ? "Cancel" : "Edit"}
          </Button>
          <ProjectGracenoteLineupList
            isEditable={lineupListIsEditable}
            lineups={gracenoteLineups}
            onSelectLineup={(lineup) => {
              setSelectedLineup(lineup);
            }}
            onDeleteLineup={(lineup) => {
              deleteProjectGracenoteLineup(lineup);
            }}
          />
        </Box>
      )}
      {selectedLineup !== null && (
        <Box>
          <IconButton onClick={() => setSelectedLineup(null)}>
            <ArrowBackIcon />
          </IconButton>

          <ProjectGracenoteLineupStationList
            addChannelGuideEntry={addChannelGuideEntry}
            lineupId={selectedLineup.lineupId}
          />
        </Box>
      )}
      <Drawer
        open={formShow}
        onClose={() => setFormShow(false)}
        className={classes.formDrawer}
        anchor="right"
      >
        <Typography variant="h2">Add a Lineup</Typography>
        <ProjectGracenoteLineupSearchList
          className={classes.root}
          onSelectLineup={(lineup) => {
            addProjectGracenoteLineup({
              projectId: projectId,
              lineup: lineup,
            });
            setFormShow(false);
          }}
        />
      </Drawer>
    </div>
  );
}

export function WorkspaceMainPage(props) {
  const { id } = useParams();
  const {
    project,
    addProjectGracenoteLineup,
    deleteProjectGracenoteLineup,
    selectActiveProject,
    channelGuides,
    selectedChannelGuide,
    addProjectChannelGuideEntry,
    deleteProjectChannelGuideEntry,
    updateProjectChannelGuideEntry,
    addProjectChannelGuide,
    deleteProjectChannelGuide,
    publishProjectChannelGuide,
    selectActiveProjectChannelGuide,
    projectChannelGuideSelectClear,
    projectChannelGuideEntries,
  } = props;

  const classes = useStyles();

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    selectActiveProject(id);
    projectChannelGuideSelectClear();
  }, [projectChannelGuideSelectClear, selectActiveProject, id]);

  const handleTabChange = (_event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className={classes.root}>
      {project && (
        <div className={classes.root}>
          <Drawer
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            anchor="left"
          >
            <div className={classes.toolbar} />
            <AppBar position="static" color="default">
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="fullWidth"
              >
                <Tab label="Lineups" />
                {/* <Tab label="Adhoc" /> */}
                <Tab label="Search" />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <ProjectGracenoteLineupSelection
                  gracenoteLineups={project.gracenoteLineups}
                  projectId={project.id}
                  addProjectGracenoteLineup={addProjectGracenoteLineup}
                  deleteProjectGracenoteLineup={(lineup) => {
                    deleteProjectGracenoteLineup({
                      projectId: project.id,
                      ...lineup,
                    });
                  }}
                  addChannelGuideEntry={(entry) => {
                    if (selectedChannelGuide != null)
                      return addProjectChannelGuideEntry({
                        projectId: project.id,
                        channelGuideId: selectedChannelGuide.channelGuideId,
                        channelGuideEntry: entry,
                      });
                  }}
                />
              </TabPanel>
              {/* <TabPanel value={tabValue} index={1}>
                <Typography>Ad Hoc</Typography>
              </TabPanel> */}
              <TabPanel value={tabValue} index={1}>
                <GracenoteSearchStations
                  onStationSelected={(station) => {
                    if (selectedChannelGuide != null)
                      return addProjectChannelGuideEntry({
                        projectId: project.id,
                        channelGuideId: selectedChannelGuide.channelGuideId,
                        channelGuideEntry: station,
                      });
                  }}
                />
              </TabPanel>
            </AppBar>
          </Drawer>

          <div
            style={{
              flexGrow: 1,
            }}
          >
            {selectedChannelGuide == null && (
              <ProjectChannelGuideEditList
                channelGuides={channelGuides}
                onChannelGuideSelected={(channelGuide) => {
                  selectActiveProjectChannelGuide({
                    projectId: project.id,
                    channelGuideId: channelGuide.id,
                  });
                }}
                onChannelGuideAdded={(channelGuide) => {
                  addProjectChannelGuide({
                    projectId: project.id,
                    channelGuide: { ...channelGuide, nextOrdinal: 0 },
                  });
                }}
                onChannelGuideDeleted={(channelGuideId) => {
                  console.log(channelGuideId);
                  deleteProjectChannelGuide({
                    projectId: project.id,
                    channelGuideId,
                  });
                }}
              />
            )}

            {selectedChannelGuide != null && (
              <div>
                <div>
                  <Typography>{selectedChannelGuide.channelGuideId}</Typography>
                </div>
                <IconButton
                  onClick={() => {
                    projectChannelGuideSelectClear();
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
                <Button
                  onClick={() => {
                    publishProjectChannelGuide(selectedChannelGuide);
                  }}
                >
                  Publish
                </Button>
                <ChannelGuideComposer
                  channelGuideEntries={projectChannelGuideEntries}
                  deleteChannelGuideEntry={(entry) => {
                    deleteProjectChannelGuideEntry({
                      projectId: project.id,
                      channelGuideId: selectedChannelGuide.channelGuideId,
                      channelGuideEntryId: entry.id,
                    });
                  }}
                  updateChannelGuideEntry={(entry) => {
                    updateProjectChannelGuideEntry({
                      projectId: project.id,
                      channelGuideId: selectedChannelGuide.channelGuideId,
                      channelGuideEntryId: entry.id,
                      channelGuideEntry: entry,
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default connect(
  (state) => ({
    project: select.selectProject(state),
    channelGuides: projectChannelGuides.fetchProjectChannelGuides(state),
    selectedChannelGuide: projectChannelGuides.selectProjectChannelGuide(state),
    projectChannelGuideEntries: projectChannelGuideEntries.fetchProjectChannelGuideEntries(
      state
    ),
  }),
  {
    selectActiveProjectChannelGuide,
    addProjectGracenoteLineup,
    deleteProjectGracenoteLineup,
    selectActiveProject,
    addProjectChannelGuide,
    deleteProjectChannelGuide,
    projectChannelGuideSelectClear,
    addProjectChannelGuideEntry,
    deleteProjectChannelGuideEntry,
    updateProjectChannelGuideEntry,
    publishProjectChannelGuide,
  }
)(WorkspaceMainPage);
