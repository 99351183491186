import {
  SEARCH_GRACENOTE_STATIONS,
  SEARCH_GRACENOTE_STATIONS_FAILURE,
  SEARCH_GRACENOTE_STATIONS_SUCCESS
} from "./actions";

const initialstate = {
  stations: [],
  isLoading: false,
  error: null
};

export default function(state = initialstate, action) {
  switch (action.type) {
    case SEARCH_GRACENOTE_STATIONS: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }
    case SEARCH_GRACENOTE_STATIONS_SUCCESS: {
      return {
        stations: [...action.payload.hits.map(station => station.station)],
        isLoading: false,
        error: null
      };
    }
    case SEARCH_GRACENOTE_STATIONS_FAILURE: {
      return {
        lineups: [],
        isLoading: false,
        error: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
