// Channels API

export const FETCH_GRACENOTE_CHANNELS = "FETCH_GRACENOTE_CHANNELS";
export const FETCH_GRACENOTE_CHANNELS_SUCCESS = "FETCH_GRACENOTE_CHANNELS_SUCCESS";
export const FETCH_GRACENOTE_CHANNELS_FAILURE = "FETCH_GRACENOTE_CHANNELS_FAILURE";

export const fetchGracenoteChannels = (lineupId) => ({
  type: FETCH_GRACENOTE_CHANNELS,
  payload: lineupId
});

export const fetchGracenoteChannelsSuccess = (channels) => ({
  type: FETCH_GRACENOTE_CHANNELS_SUCCESS,
  payload: channels
});

export const fetchGracenoteChannelsFailure = (message) => ({
  type: FETCH_GRACENOTE_CHANNELS_FAILURE,
  payload: message
});