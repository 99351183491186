import React from "react";
import { Link } from "react-router-dom";
import {
  ListItem,
  ListItemText,
  Typography,
  Divider,
  IconButton,
  ListItemSecondaryAction
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";

export default function ProjectListItem(props) {
  const { project, onClick, isEditable, onDelete } = props;

  return (
    <ListItem button={!isEditable} key={project.id}>
      <ListItemText
        disableTypography={true}
        primary={
          <div>
            {!isEditable && (
              <Link to={`/project/${project.id}`}>
                <Typography variant="subtitle1" color="textPrimary">
                  {project.name}
                </Typography>
              </Link>
            )}
            {isEditable && (
              <Typography variant="subtitle1" color="textPrimary">
                {project.name}
              </Typography>
            )}
          </div>
        }
        // secondary={
        //   <React.Fragment>
        //     <Typography variant="body2" color="textPrimary">
        //       {station.station.stationId}
        //     </Typography>
        //     <Typography variant="body2" color="textPrimary">
        //       {station.station.callSign}
        //     </Typography>
        //     <Typography variant="body2" color="textPrimary">
        //       Broadcast Languages :{" "}
        //       {station.station &&
        //         station.station.bcastLangs &&
        //         station.station.bcastLangs.join(", ")}
        //     </Typography>
        //     <Typography variant="body2" color="textPrimary">
        //       Video Quality :{" "}
        //       {station.station &&
        //         station.station.videoQuality &&
        //         station.station.videoQuality.videoType}
        //     </Typography>
        //   </React.Fragment>
        // }
      />
      {isEditable && (
        <ListItemSecondaryAction>
          <IconButton
            onClick={_event => {
              onDelete(project.id);
            }}
            edge="end"
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
