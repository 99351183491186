import { combineReducers } from "redux";

import lifecycle from "./lifecycle";
import gracenoteLineups from "./gracenote-lineups/reducer";
import gracenoteChannels from "./gracenote-channels/reducer";
//import channelGuide from "./channel-guide/reducer";
import stationSearch from "./station-search/reducer";
import auth from "./auth/reducer";
import projects from "./projects/reducer";

import projectGracenoteLineups from "./project-workspace/gracenote-lineups/reducer";
import projectChannelGuides from "./project-workspace/channel-guides/reducer";
import projectChannelGuideEntries from "./project-workspace/channel-guides/entries/reducer";

export default combineReducers({
  lifecycle,
  projects,
  projectGracenoteLineups,
  projectChannelGuides,
  projectChannelGuideEntries,
  gracenoteLineups,
  gracenoteChannels,
  // channelGuide,
  stationSearch,
  auth
});
